import './Linktree.css';
import LinktreeLink from "./LinktreeLink";
import PPIcon from '../../images/Icon.webp';

import Constants from '../../Constants';

export default function Linktree() {
    return (
        <div className='linkTreeContainer'>
            <div className="mainFlex">
                <img className="mainIcon" src={PPIcon} alt='Pixel Pasture Icon'></img>
                <h2>Pixel Pasture</h2>
                <LinktreeLink
                    name="Website"
                    link={Constants.WebsiteLink}
                />
                <LinktreeLink
                    name="Instagram"
                    link={Constants.InstagramLink}
                />
                <LinktreeLink
                    name="Twitter"
                    link={Constants.TwitterLink}
                />
                <LinktreeLink
                    name="Bluesky"
                    link={Constants.BlueskyLink}
                />
                   <LinktreeLink
                    name="Threads"
                    link={Constants.ThreadsLink}
                />
                   <LinktreeLink
                    name="YouTube"
                    link={Constants.YoutubeLink}
                />
                 <LinktreeLink
                    name="Discord"
                    link={Constants.DiscordLink}
                />
            </div>
        </div>
    );
}